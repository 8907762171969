












































































































































































































































































import { Component, Mixins } from 'vue-property-decorator'
import Methods from '@/components/methods'
import { get } from '@/http'
import { alert } from '@/components/pop'

@Component
export default class DemandDetails extends Mixins(Methods) {
    details: any = {}

    created(): void {
        document.body.scrollTop = 0
        document.documentElement.scrollTop = 0
        this.getStoreDetails()
    }
    getStoreDetails(): void {
		get('store/detail', {
			sid: this.$route.params.id,
			userId: sessionStorage.getItem('userId')
        }).then((res: any) => {
            this.details = res.data
        })
	}
    openContact(): void {
        if (sessionStorage.getItem('userId') == null) {
            alert({text: '请登录后再进行联系', state: 1})
            return
        }
		alert({text: '我们会尽快为您联系，请稍后', state: 1})
	}
}
